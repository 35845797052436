import React from "react";
import styled from "styled-components";
import { Header3, Header5, reporaColor, ReporaButton, RadioComponent } from "../../../components/global/commonStyles";
import { Wrapper, HeaderWrapper, FormWrapper, ButtonContainer } from "./CommonStyles";
import { RadioGroup, Radio } from "react-radio-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const RadioWrapper = styled(RadioComponent)`
  height: 80px;
  background: ${reporaColor.grey98};
  margin-top: 10px;

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    border: solid 1px transparent;
    padding-left: 50px;

    span {
      padding-left: 20px;
    }
  }

  [type="radio"]:checked + label>span:after,
  [type="radio"]:not(:checked) + label>span:after {
    top: 8px;
  }

  [type="radio"]:checked + label>span:before,
  [type="radio"]:not(:checked) + label>span:before {
    top: 13px;
  }

  [type="radio"]:checked + label {
    border-color: ${reporaColor.warmGrey};
  }
`;

const RadioName = styled.div`
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
`;

const RadioInfo = styled.div`
  font-size: 12px;
  color:${reporaColor.warmGrey};
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: ${reporaColor.warmGrey};
`;

const Step1 = props => {

  return (
    <Wrapper>
      <HeaderWrapper>
        <CloseButton onClick={props.handleBackExit}><FontAwesomeIcon icon={faTimes} /></CloseButton>
        <Header3 textAlign="center">Sign Up</Header3>
        <Header5 textAlign="center" textColor={`${reporaColor.brightSkyBlue}`}>Select your membership type to get started</Header5>
      </HeaderWrapper>

      <FormWrapper>
        <RadioGroup name="userRole" selectedValue={props.selectedValue} onChange={props.handleChange}>
          <RadioWrapper>
            <Radio value="CUSTOMER" id="userRole1" />
            <label htmlFor="userRole1">
              <span>
                <RadioName>Customer</RadioName>
                <RadioInfo>I want to find, view, and rate the reps I interact with.</RadioInfo>
              </span>
            </label>
          </RadioWrapper>
          <RadioWrapper>
            <Radio value="REP" id="userRole2" />
            <label htmlFor="userRole2">
              <span>
                <RadioName>Rep</RadioName>
                <RadioInfo>I want to view my ratings and curate my reputation.</RadioInfo>
              </span>
            </label>
          </RadioWrapper>
          <RadioWrapper>
            <Radio value="ADMIN" id="userRole3" />
            <label htmlFor="userRole3">
              <span>
                <RadioName>Company</RadioName>
                <RadioInfo>I want to track my company’s reputation.</RadioInfo>
              </span>
            </label>
          </RadioWrapper>
        </RadioGroup>
        <ButtonContainer className="m-b-45">
          <ReporaButton className="m-t-10" textAlign="center" onClick={props.nextStepSubmit} disabled={props.selectedValue === ""} full>Continue</ReporaButton>
        </ButtonContainer>
      </FormWrapper>
    </Wrapper>
  );
};

export default Step1;
